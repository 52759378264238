nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 170px;
  
    ul {
      font-size: 13px;
      list-style: none;
      padding: 0;
      margin: 30px 0 0 0;
      li {
        line-height: 2.5;
        position: relative;
        a {
          color: $white-color;
          display: inline-block;
          margin-left: 0;
          padding: 0;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }
        a:hover {
          margin-left: 4px;
        }
      }
    }
  }